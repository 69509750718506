import Vue from 'vue'
import App from './App.vue'


import './assets/css/normalize.css'
import 'animate.css'
import './assets/css/pc.css'
import 'lib-flexible/flexible.js'
import qs from 'qs'
Vue.prototype.$qs = qs

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


Vue.config.productionTip = false

Vue.prototype.debounce = function (fn, delay) {
  let timer = null
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(fn, delay)
  }
}


new Vue({
  render: h => h(App),
}).$mount('#app')
