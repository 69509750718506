<template>
  <div id="app">
    <div  v-show="noPop">
    <div class="floatFrame" @click="officialCommunity" v-show="showFrame">
      <a href="https://game.naver.com/lounge/Dear_My_Cinema/home"
      target="_blank"
        ><img
          src="./assets/firstPage/banner.png"
          class="button"
          
          alt=""
          @click="goForum"
      /></a>
      <span @click="closeFrame"></span>
    </div>
      <div class="openedMenu animate__animated animate__fadeIn" v-if="menu">
        <img src="./assets/openedMenu.png" alt="" />
        <img src="./assets/x.png" alt="" @click="close" />
        <ul class="menuList" id="menuList">
          <li
            v-for="(item, index) in menuList"
            :key="index"
            @click="jumpMenu(index)"
            :class="curIndex == index ? 'active' : ''"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="menu cursor" @click="openMenu" v-else>
        <img src="./assets/firstPage/menu.png" alt="" />
      </div>
    </div>
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide>
        <div class="firstPage">
          <video
            autoplay
            muted
            loop
            ref="video"
            src="./assets/firstPage/video.mp4"
            width="100%"
          ></video>
          <div class="banner">
            <img
              src="./assets/firstPage/text.png"
              class="banner-title"
              alt=""
            />
            <img
              src="./assets/firstPage/btn.png"
              @click="goReserve"
              class="banner-btn cursor"
              alt=""
            />
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="secondPage">
          <div class="content">
            <img src="./assets/secondPage/title.png" class="title" alt="" />
            <div class="reserve-content">
              <div class="reserve">
                <el-input v-model="input" type="number" class="no-number" autofocus="false" @input="input=input.slice(0,11)" maxlength="11" placeholder="“-”를 제외한 번호만 입력해 주세요."></el-input>
                <div class="btnImg cursor">
                  <img
                    src="./assets/secondPage/btn.png"
                    alt=""
                    @click="preOrder"
                    
                  />
                </div>
              </div>
              <div class="authorize">
                <div class="authorize-left cursor">
                  <el-checkbox v-model="checked"></el-checkbox>
                  <img
                    src="./assets/secondPage/authorizeHint.png"
                    class="txt"
                    alt=""
                  />
                  <img
                    src="./assets/secondPage/authorizeBtn.png"
                    alt=""
                    class="popTxt"
                    @click="popUserInfo"
                  />
                </div>
                <div class="channel">
                  <div class="ios cursor">
                    <img :src="iosChecked ? radioC : radio" alt="" @click="iosChecked = true; aosChecked = false">
                    <span>iOS</span>
                  </div>
                  <div class="AOS cursor">
                    <img :src="aosChecked ? radioC : radio"  @click="aosChecked = true; iosChecked = false">
                    <span>AOS</span>
                  </div>
                </div>
              </div>
              <div class="jump">
                <img src="./assets/secondPage/img.png" alt="" />
                <div class="jumpTo">
                  <a href="https://apps.apple.com/kr/app/%EC%8B%9C%EB%84%A4%EB%A7%88%EB%A5%BC-%EB%B6%80%ED%83%81%ED%95%B4/id1591359952" target="_blank" @click="appleStore"
                    ><img src="./assets/secondPage/appleStore.png" alt=""
                  /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.morefunkr.wpczb&hl=ko" target="_blank" @click="googlePlay"
                    ><img src="./assets/secondPage/googlePlay.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="attention">
                <img src="./assets/secondPage/attention.png" alt="">
              </div>
            </div>
          </div>
          <div
            class="tremPopUp animate__animated animate__fadeIn"
            v-if="showUserInfoPopup"
          >
            <div class="mask"></div>
            <div class="termPopContent">
              <img src="./assets/popup/userInfo.png" alt="" />
              <img src="./assets/popup/btn.png" @click="closeUserInfoPopUp" />
            </div>
          </div>
          <div class="completedOrder" v-if="orderCompleted">
            <div class="mask"></div>
            <div class="popContent">
              <img src="./assets/popup/preorderL.png" alt="" />
              <img src="./assets/popup/preorderR.png" alt="" />
              <a href="https://game.naver.com/lounge/Dear_My_Cinema/home" target="_blank" @click="goForum" class="goForumPage"
                ><img src="./assets/popup/preorderLeftBtn.png" alt=""
              /></a>
              <a href="https://cine.gamemorefun.net/" target="_blank" @click="goEvent" class="goEventPage"
                ><img src="./assets/popup/preorderRightBtn.png" alt=""
              /></a>
              <img src="./assets/popup/x.png" @click="closePopup" alt="" />
            </div>
          </div>
          <div class="pleaseArgee" v-if="disagree">
            <div class="mask"></div>
            <div class="pleaseArgeePopup">
              <img :src="!channelChoose? channelPopimg: argeePopImg" alt="" />
              <span @click="closePleaseArgeePopup"></span>
            </div>
          </div>
          <div class="comfirmTel" v-if="confirmTelPopup">
            <div class="mask"></div>
            <div class="comfirmTelPopup animate__animated animate__fadeIn">
              <img src="./assets/popup/confirmTel.png" v-show="worngNum" alt="" />
              <img src="./assets/popup/repeat.png" v-show="repeat" alt="">
              <span @click="comfirmPhoneNum"></span>
            </div>
          </div>

          <!--预约活动结束，跳到商店的弹框-->
          <div v-if="storePop">
            <div class="mask"></div>
            <div class="pop-goStore animate__animated animate__fadeIn">
              <img src="./assets/popup/x.png" @click="closePopup" class="pop-close"  alt="" />
              <img src="./assets/popup/toStore1.png" class="pop-img" alt="">
              <div class="pop-store">
                <a href="https://play.google.com/store/apps/details?id=com.morefunkr.wpczb" target="_blank"></a>
                <a href="https://apps.apple.com/kr/app/id1591359952" target="_blank"></a>
                <a href="https://galaxystore.samsung.com/detail/com.hw.wpczbsam" target="_blank"></a>
                <a href="https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000763474" target="_blank"></a>
              </div>
            </div>
          </div>

        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="thirdPage">
          <div class="content">        
            <img
              src="./assets/thirdPage/silder1.png"
              alt=""
            />  
            <img src="./assets/thirdPage/btn.png" class="cursor" @click="goReserve" alt="" />
            <img src="./assets/thirdPage/line.png" alt="">
            <el-slider v-model="totalNum" :disabled="true" :show-tooltip="false" :min="0" :max="3000" input-size="small"></el-slider>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="fourthPage">
          <div class="content">
            <div class="fourthpPageBtn">
              <img
                src="./assets/fourthPage/leftBtn.png"
                @click="goCopyLink"
                class="cursor"
                alt=""
              />
              <a href="https://game.naver.com/lounge/Dear_My_Cinema/home" target="_blank" @click="goForum" class="cursor">
                <img src="./assets/fourthPage/rightBtn.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="goCopyLink" v-if="copyLinkPopup">
          <div class="mask"></div>
          <div class="goCopyLinkContent">
            <p id="linkUrl">{{message}}</p>
            <img src="./assets/popup/linkPopup.png" alt="" />
            <button type="button" @click="copyLink"></button>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="lastPage">
          <div class="swiper">
            <div class="swiperContent">
              <swiper ref="mySwiper1" :options="swiperOptions1">
                <swiper-slide>
                  <img src="./assets/lastPage/0.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="./assets/lastPage/1.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="./assets/lastPage/2.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="./assets/lastPage/3.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="./assets/lastPage/4.jpg" alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src="./assets/lastPage/5.jpg" alt="" />
                </swiper-slide>
              </swiper>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </div>
          </div>
          <footer>
            <a
              href="https://fbcbko.happygamestar.com/rest/v2/games/agreement?type=terms"
              target="_blank"
              >이용약관</a
            >
            <a
              href="https://cine.gamemorefun.net/policy.html"
              target="_blank"
              >개인정보처리방침</a
            >
            <div class="company_info">
              <p>사업자등록번호 : 681-81-01530 l ㈜아이클럭워크</p>
              <p>대표이사 : 샤오젠 l 서울특별시 금천구 가산디지털2로 98, 2동 1107호</p>
              <p>© iClockwork CO., ALL RIGHTS RESERVED.</p>
            </div>
          </footer>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import axios from "axios";
export default {
  name: "app",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      curIndex: 0,
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },
        mousewheel: true,
        direction: "vertical",
        height: window.innerHeight,
        width: window.innerWidth,
        // height: 1080,
        slidesPerView: 1,
        on: {
          slideChangeTransitionEnd: function () {
            let lis = document.getElementsByTagName("li");
            let lisLength = lis.length;
            for (let i = 0; i < lisLength; i++) {
              lis[i].className = "";
              lis[this.activeIndex].className = "active";
            }
          },
        },
      },
      swiperOptions1: {
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          //前进后退按钮
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      noPop:true,
      menu: false,
      showFrame: true,
      checked: false,
      iosChecked: false,
      aosChecked: false,
      input: null,
      menuList: ["MAIN", "사전예매", "인원달성 이벤트", "SNS 공유 이벤트", "게임 소개"],
      showUserInfoPopup: false, // 个人信息收集条款弹框
      orderCompleted: false, // 预约完成弹框
      disagree: false, // 未勾选同意弹框
      copyLinkPopup: false, // 复制链接的弹框
      message: "https://cine.gamemorefun.net/", // 链接内容
      confirmTelPopup: false, // 确认电话号码弹框
      totalNum: 0, // 预约人数
      index: 0, // 第三页预约进度条
      argeePopImg: require('./assets/popup/pleaseArgee.png'),
      channelPopimg: require('./assets/popup/channel.png'),
      radioC: require('./assets/secondPage/radio-chosed.png'),
      radio: require('./assets/secondPage/radio.png'),
      channelChoose: true,
      platform: '',
      worngNum: false,
      repeat: false,
      storePop: false
    };
  },
  created() {},
  mounted() {
    this.getReserveNum();
    this.pageEvent("pc_pv");
    //   this.$refs.video.addEventListener("contextmenu",function(){//取消右键事件
    //     return false;
    //  });
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    openMenu() {
      this.menu = true;
      let pageIndex = this.$refs.mySwiper.swiper.activeIndex;
      if (pageIndex <= 2) {
        this.curIndex = this.$refs.mySwiper.swiper.activeIndex;
      } else if (pageIndex >= 3) {
        this.curIndex = this.$refs.mySwiper.swiper.activeIndex - 1;
      }
    },
    close() {
      this.menu = false;
    },
    goReserve() {
      this.curIndex = 1
      this.swiper.slideTo(1, 1000, false); //切换到第一个slide，速度为1秒
      
    },
    jumpMenu(index) {
      this.curIndex = index;
      this.swiper.slideTo(index, 1000, false);
    },
    popUserInfo() {
      // 收集用户信息条款弹框
      this.noPop = false;
      this.showUserInfoPopup = true;
     this.disable()
    },
    closeUserInfoPopUp() {
      this.noPop = true;
      this.showUserInfoPopup = false;
      this.enable()
    },
    appoint() {
      let me = this;
      axios
        .post("https://admin.csback.gamemorefun.net/api/v6/checkin", {
          tel: this.input,
          platform: this.platform
        })
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            me.noPop = false;
            me.orderCompleted = true;  
            this.$refs.mySwiper.swiper.mousewheel.disable();
            me.getReserveNum(); // 获取预约人数
          } else if(res.data.code == 423) {
            this.confirmTelPopup = true
            this.worngNum = false
            this.repeat = true
          }else {
            me.$message.error(res.data.message);
          }
        });
    },
    preOrder() {
      this.noPop = false;
      this.storePop = true;  
      this.disable();
      return
      if(!this.checked) {
        this.noPop = false;
        this.disagree = true;
        this.disable()
        return
      }
      if(!this.iosChecked && !this.aosChecked) {
        this.noPop = false;
        this.disagree = true;
         this.disable()
        this.channelChoose = false;
        return
      } 
     if(this.iosChecked) {
       this.platform = 'iOS'
     } else if (this.aosChecked) {
       this.platform = 'AOS'
     }
      // 立即预约
        if (this.input != null) {
          if (this.validate(this.input)) {     
            this.appoint();
          } else {
            this.noPop = false;
            this.confirmTelPopup = true;
            this.repeat = false
            this.worngNum = true
          }
        } else {
          this.noPop = false;
          this.confirmTelPopup = true;
         
      } 
      this.disable()
    },
    closePopup() {
      this.noPop = true;
      this.storePop = false;
      this.enable()
    },
    closePleaseArgeePopup() {
      this.noPop = true;
      this.disagree = false;
      this.enable()
    },
    closeFrame() {
      this.showFrame = false;
    },
    goCopyLink() {
      // 打开复制链接弹框
      this.noPop = false;
      this.copyLinkPopup = true;
      this.disable()
    },
    copyLink() {
      // 复制页面链接
      this.message = document.getElementById("linkUrl").innerText;
      let me = this;
      this.$copyText(this.message).then(
        function (e) {
          // me.$message.success("copied");
          me.pageEvent("copy");
          me.noPop = true;
          me.copyLinkPopup = false;
          me.enable()
        },
        function (e) {
          // me.$message.error("fail");
        }
      );
    },
    disable() {
        this.$refs.mySwiper.swiper.mousewheel.disable(); // 鼠标滚轮不可控制
        this.$refs.mySwiper.swiper.allowTouchMove = false; // 不能拖动切换
    },
    enable() {
        this.$refs.mySwiper.swiper.mousewheel.enable();
        this.$refs.mySwiper.swiper.allowTouchMove = true;
    },
    comfirmPhoneNum() {
      this.noPop = true;
      this.confirmTelPopup = false;
      this.input = "";
      this.getReserveNum();
      this.enable()
    },
    getReserveNum() {
      
      //获取预约人数
      axios
        .get("https://admin.csback.gamemorefun.net/api/v6/getTotal")
        .then((res) => {
          if (res.data.code === 200) {
            this.totalNum = res.data.data.total;
          }
        });
    },
    officialCommunity() {
      // 官方社群
      this.pageEvent("click_communities");
    },
    goForum() {
      // 官方论坛跳转
      this.noPop = true;
      this.pageEvent("click_forum");
      this.input = "";
      this.orderCompleted = false;
      this.enable()
    },
    goEvent() {
      // 活动页面跳转
      this.noPop = true;
      this.input = "";
      this.orderCompleted = false;
      this.enable()
    },
    appleStore() {
      this.pageEvent("click_apple_btn");
    },
    googlePlay() {
      this.pageEvent("click_google_btn");
    },
    validate(tel) {
      // 手机号校验
      let reg = /^\d{11}$/;
      return reg.test(tel);
    },
    pageEvent(data) {
      // 记录页面事件
      axios
        .post("https://admin.csback.gamemorefun.net/api/pageEvent", {
          event_name: data,
          ver: 6,
        })
        .then((res) => {
        });
    },
  },
};
</script>

<style lang="scss">
.el-slider__runway {
  position: absolute!important;
  width: 82.3%!important;
  top: 62.6%!important;
  left: 50.8%;
  z-index: 99;
  transform: translateX(-50%);
  
  background-color: #fff!important;
}
.el-slider__button {
  width: 0!important;
  height: 0!important;
  border-color: #cb1414!important;
}
.el-slider__bar {
  background-color: #cb1414!important;
}
.no-number {
  -moz-appearance: textfield;
}
.no-number input[type="number"] {
  -moz-appearance: textfield;
}
* {
  padding: 0;
  margin: 0;
}
html,
body {
  overflow: hidden;
  max-width: 1920px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
}
#app > .swiper-container > swiper-slide {
  width: 100%;
  height: 100%;
}
form,
ul,
li,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
input,
select,
button {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
input,
select,
button {
  border: none;
  outline: 0;
}
.openedMenu {
  position: relative;
  width: 16%;
}
.menuList {
  position: absolute;
  top: 31%;
  left: 12%;
  width: 100%;
  line-height: 1.1067rem;
  color: #fff;
}
.menuList li {
  width: 60%;
  border-bottom: 1px solid #a49e9c;
  font-size: .32rem;
  text-align: center;
  cursor: pointer;
  font-weight: bolder;
}
.active {
  color: #ffebb2;
}
.firstPage,
.secondPage,
.thirdPage,
.fourthPage,
.lastPage {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
}
.menu,
.openedMenu {
  position: absolute;
  top: 60px;
  left: 60px;
  z-index: 2;
}
.openedMenu img {
  width: 100%;
}
.menu {
  width: 10%;
  transition: 0.5s;
}
.menu:hover {
  transform: scale(1.1);
}
.openedMenu img:nth-child(2) {
  position: absolute;
  top: 26%;
  left: 75%;
  width: 0.5rem;
  height: 0.5rem;
  background: transparent;
}
.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.banner .banner-title {
  position: absolute;
  top: 10%;
  left: 54%;
  width: 36%;
  transform: translateX(-50%);
  z-index: 1;
}
.banner .banner-btn {
  position: absolute;
  top: 60%;
  width: 30%;
  z-index: 2;
}
/* .banner .banner-btn:hover{
  transform: scale(1.05);
} */
.floatFrame {
  position: fixed;
  bottom: 0.8rem;
  right: 0.8rem;
  width: 10%;
  z-index: 2;
  transition: all 0.5s;
}
.floatFrame a img {
  width: 80%;
}
.floatFrame:hover {
  transform: scale(1.1);
}
.floatFrame > span {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 0;
}

.secondPage {
  width: 100vw;
  height: 100vh;
  background: url(./assets/secondPage/bg.png) no-repeat center center;
  background-size: 100%;
}
input::placeholder {
  font-size: .32rem;
  font-weight: bolder;
}
.el-input__inner::placeholder {
  color: #535146!important;
}
.secondPage .content {
  width: 48%;
}
.reserve-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reserve {
  display: flex;
  width: 90%;
  height: 7%;
}
.reserve .el-input {
  width: 70%;
}
.reserve .btnImg {
  width: 30%;
  height: 100%;
  margin-left: 0.6667rem;
}
.btnImg > img {
  display: block;
  width: 100%;
  transition: all 0.4s;
}
.reserve .el-input__inner {
  margin-right: 0.5333rem;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #32302a url('./assets/secondPage/border.png')  no-repeat center center;
  background-size: 95% 80%;
  border: 2px solid #e6d80c;
  border-radius: .2667rem;
  text-indent: .2667rem;
  font-size: 24px;
  color: #fff;
}

.el-input__inner:focus,.el-input__inner:hover {
  outline: none !important;
  border: 2px solid #e6d80c !important;
}
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: 0!important;
}
.reserve img:hover {
  transform: scale(1.1);
}
.content {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content .title {
  width: 60%;
  height: 50%;
  margin: 0 0 0.5333rem 0;
}
.content > img:nth-child(5) {
  width: 60%;
  transition: all 0.8s;
}
.content > img:nth-child(5):hover {
  transform: scale(1.1);
}
.authorize {
  display: flex;
  align-items: center;
  margin-top: 0.1333rem;
  width: 89%;
  box-sizing: border-box;
}
.authorize-left {
  display: flex;
  width: 72%;
}
.authorize .popTxt {
  width: 12%;
}
.authorize .channel{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 auto;
}
.ios, .AOS {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.ios>img,.AOS>img {
  width: .2667rem;
  border-radius: .0533rem;
}
.ios {
  margin-right: .3rem;
}
.ios>span, .AOS>span {
  margin-left: .16rem;
  
  color: #fffdd9;
  line-height: .1867rem;
  font-size: .2133rem;
  font-weight: bold;
}
.el-checkbox {
  width: 14px !important;
  height: 14px !important;
}
.el-checkbox__inner {
  z-index: 0 !important;
  background: #8d8270 !important;
  border: 0 !important;
}
.el-checkbox__inner::after {
  top: 2px !important;
  left: 5px !important;
}
.authorize .txt {
  width: 4.4667rem;
  margin: 0 20px 0 8px;
}
.jump {
  position: relative;
  margin-top: 30px;
  width: 94%;
}
.jump img {
  display: block;
  width: 100%;
}
.jumpTo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4% 0 10% 25%;
  width: 20%;
}
.attention img{
  width: 8.48rem;
}

.jumpTo a:nth-child(1) > img {
  margin-bottom: 10px;
}
.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background: rgba(0, 0, 0, 0.9);
}
.tremPopUp {
  position: relative;
  width: 100%;
  height: 100%;
}
.termPopContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.termPopContent > img:nth-child(2) {
  margin-top: -100px;
}
.completedOrder .popContent {
  position: absolute;
  top: 48%;
  left: 50%;
  background: url(./assets/popup/preorderCompletedBg.png) no-repeat;
  transform: translate(-50%, -50%);
  width: 981px;
  height: 516px;
  z-index: 9;
}
.completedOrder .popContent > img:nth-child(1) {
  position: absolute;
  bottom: 115px;
  left: 468px;
  z-index: 1;
}
.completedOrder .popContent > img:nth-child(2) {
  position: absolute;
  bottom: 115px;
  right: 170px;
  z-index: 1;
}
.completedOrder .popContent .goForumPage {
  position: absolute;
  bottom: 62px;
  left: 400px;
}
.completedOrder .popContent .goForumPage img {
  transition: 0.3s;
}
.completedOrder .popContent .goForumPage img:hover {
  transform: scale(1.05);
}
.completedOrder .popContent .goEventPage {
  position: absolute;
  bottom: 62px;
  right: 110px;
}
.completedOrder .popContent .goEventPage img {
  transition: 0.3s;
}
.completedOrder .popContent .goEventPage img:hover {
  transform: scale(1.05);
}
.completedOrder .popContent > img:nth-child(5) {
  position: absolute;
  top: -50px;
  right: 0;
  transition: 0.3s;
}
.completedOrder .popContent > img:nth-child(5):hover {
  transform: scale(1.1);
}
.pleaseArgee .pleaseArgeePopup {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.pleaseArgeePopup > span {
  position: absolute;
  bottom: 82px;
  left: 41%;
  width: 178px;
  height: 50px;
}
.comfirmTel .comfirmTelPopup {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.comfirmTelPopup span {
  position: absolute;
  bottom: 28%;
  left: 41%;
  width: 178px;
  height: 50px;
}

.thirdPage {
  width: 100vw;
  height: 100vh;
  background: url(./assets/thirdPage/bg.png) no-repeat center center;
  background-size: 100%;
}

.thirdPage .content {
  position: absolute;
  top: 52.5%;
  max-width: 17rem;
  /* max-height: 17rem; */
}
.thirdPage .content > img:nth-child(1) {
  width: 90%;
  margin-top: 21%;
}
.thirdPage .content > img:nth-child(2) {
  width: 34%;
  margin-top: 10px;
  transition: 0.4s;
}
.thirdPage .content > img:nth-child(2):hover {
  transform: scale(1.1);
}
.thirdPage .content > img:nth-child(3) {
  position: absolute;
    top: 65.8%;
    left: 25.2%;
    z-index: 999;
    width: 54.3%;
  
}
.fourthPage {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(./assets/fourthPage/bgpeople.png) no-repeat center center;
  background-size: 100%;
}
.fourthPage > .content {
  position: absolute;
  top: 0% !important;
  width: 100vw;
  height: 100vh;
}
.fourthPage .fourthpPageBtn {
  margin-top: 29%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47%;
}
.fourthpPageBtn > img,
.fourthpPageBtn > a {
  width: 30%;
  transition: all 0.4s;
}
.fourthpPageBtn > a > img {
  width: 100%;
  transition: all 0.4s;
}
.fourthpPageBtn > img:nth-child(1):hover {
  transform: scale(1.1);
}
.fourthpPageBtn > a > img:hover {
  transform: scale(1.1);
}
.fourth-footer {
  position: absolute;
  bottom: 5%;
}
.goCopyLink p {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 24px;
}
.goCopyLink .goCopyLinkContent {
  position: absolute;
  top: 45%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.goCopyLinkContent button {
  position: absolute;
  bottom: 16%;
  left: 41%;
  width: 178px;
  height: 50px;
  background: transparent;
}

.lastPage {
  width: 100%;
  background: url("./assets/lastPage/bg.png") no-repeat center 0;
  background-size: contain;
}
.lastPage .content {
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0 auto;
}
.swiper {
  position: relative;
  margin-top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.swiper .swiperContent {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 16rem;
  height: 13rem;
}
.swiperContent .swiper-container {
  width: 100%;
  height: 100%;
}
.swiperContent .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}
.swiperContent .swiper-container .swiper-slide > img {
  position: absolute;
  top: 36%;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  border: 1px solid #972d26;
  border: 0.16rem solid #dd563f;
  border-radius: 12px;
  box-shadow: 3px 3px 5px #972d26;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: url("./assets/lastPage/botton-prev.png") no-repeat center center;
 left: 0%;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: url("./assets/lastPage/button-next.png") no-repeat center center;
  right: 0
}
.swiper-button-prev,
.swiper-button-next {
	position: absolute;
  top: 65%;
}

.swiper-pagination {
  bottom: -4%;
  left: 50%;
  transform: translateX(-50%);
  background: #36271d;
  padding: 5px 20px;
  border-radius: 20px;
}
.swiper-pagination-bullet {
  background: #ffffff;
}
.lastPage {
  position: relative;
}
.swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 15px;
}
.lastPage footer {
  position: absolute;
  bottom: -0.2667rem;
  left: 0;
  width: 100%;
  height: 13%;
  background: #000;
  z-index: 999;
}
.lastPage footer span,
.lastPage footer a {
  margin-top: 0.1333rem;
  color: #6f6e6d;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 12px;
  border: 1px solid #6f6e6d;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
}
.lastPage footer a:nth-child(1) {
  margin-right: 0.4rem;
}
.company_info {
  margin-top: .4rem;
  color: #575655;
}
.company_info p {
  line-height: .2667rem;
}
.pop-goStore {
  position: relative;
  z-index: 9999;
  width: 15rem;
  margin: 0 auto;
  .pop-close {
    position: absolute;
    right: 0;
    top:3.3rem;
    &:hover{
      transform: scale(1.1);
    }
  }
  .pop-img {
    width: 15rem;
    margin-top: 4rem;
  }
  .pop-store {
    position: absolute;
    bottom: .35rem;
    right: .2rem;
    width: 10rem;
    z-index: 9999999;
    a {
      display: inline-block;
      width: 3.6rem;
      height: 1.1rem;
      margin-right: .15rem;
    }
  }
}
</style>
